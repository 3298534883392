import React from 'react';
import Layout from '/src/components/layout';
import Head from '/src/components/head';

export default ({ data, location }) => {
	return (
		<>
			<Head>
				<title>このページは存在しません。 | たらこ</title>
			</Head>

			<Layout>
				<main>このページは存在しません。</main>
			</Layout>
		</>
	);
};
